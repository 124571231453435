/*
  Import Tailwind's base styles into the browser.
*/
import "core-js/stable";
import "regenerator-runtime/runtime";
import "./src/styles/globals.css"
//import "./src/styles/styles.scss"
import 'bootstrap/dist/css/bootstrap.min.css';
import "tailwindcss/dist/base.min.css";


export const onClientEntry = () => {
    // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
    if (!(`IntersectionObserver` in window)) {
        import(`intersection-observer`)
        console.log(`# IntersectionObserver is polyfilled!`)
    }    
}
